import { Box, Button, Card, CircularProgress, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import axios from 'axios';
import React, { useEffect } from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import { API_URL } from '../../../global/constants';
import { Header } from '../../shared/Header';
import Questions from './Questions';
import Review from './Review';
import SkillsWeightage from './SkillsWeightage';
import Timestamps from './Timestamps';
import Videos from './Videos';
import Decimal from 'decimal.js';
import JobCreation from './JobCreation';

const CreateMockInterviewQuestions = ({ type }) => {
	const { job_id, pilot_id } = useParams();
	const navigate = useNavigate();
	const [loading, setLoading] = React.useState(false);
	const [activeStep, setActiveStep] = React.useState(0);
	const [details, setDetails] = React.useState({
		job_id: job_id,
		job_role: '',
		job_description: '',
		job_poster: '',
		language: '',
		skills: [],
		interview_level: '',
		topicId: '',
		question_video_link: '',
		mobile_question_video_link: '',
		listening_timestamp: {
			start_time: null,
			end_time: null,
		},
		questions: [
			{
				question_text: '',
				is_last_question: false,
				question_id: 0,
				start_time: null,
				end_time: null,
				skill_type: '',
				skip_evaluation: false,
				skill: '',
				sub_skill: [],
				definition: '',
			},
		],
		weightage: [],
		interview_type: 'full_interview',
		desktop_intro_video: '',
		mobile_intro_video: '',
		is_new_format: true,
	});

	const steps = [
		'Job Creation',
		'Add Videos',
		'Add Questions',
		'Add Skills Weightage',
		'Add Timestamps',
		'Review',
	];

	const updatePilot = async () => {
		const { data } = await axios.put(
			`${API_URL}/labs/mockinterview/update-pilot/${pilot_id}`,
			details,
			{
				headers: {
					'Content-Type': 'application/json',
					authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
				},
			}
		);

		// Create a new FormData object
		const formData = new FormData();

		// Append fields to the FormData object
		formData.append('job_role', details.job_role);
		formData.append('job_description', details.job_description);
		formData.append('job_poster', details.job_poster); // This is the image file
		formData.append('topicId', details.topicId);
		formData.append('interview_level', details.interview_level);
		formData.append('skills', details.skills.join(','));
		formData.append('language', details.language);

		for (const [key, value] of formData.entries()) {
			console.log(`${key}:`, value);
		}

		const response = await axios.put(
			`${API_URL}/labs/update-mock-interview/${job_id}`,
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
					authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
				},
			}
		);
		if (data.success && response.data) {
			navigate('/mock-interviews/dashboard', {
				state: { refetch: true },
			});
			toast.success(data.message);
		} else {
			toast.error(data.message);
			console.log(data);
			console.log(response.data);
		}
	};

	const createPilot = async () => {
		// Create a new FormData object
		const formData = new FormData();

		// Append fields to the FormData object
		formData.append('job_role', details.job_role);
		formData.append('job_description', details.job_description);
		formData.append('topicId', details.topicId);
		formData.append('interview_level', details.interview_level);
		formData.append('skills', details.skills.join(','));
		formData.append('job_poster', details.job_poster);
		formData.append('language', details.language);

		// Append other details as needed
		formData.append('question_video_link', details.question_video_link);
		formData.append('mobile_question_video_link', details.mobile_question_video_link);
		formData.append('listening_timestamp[start_time]', details.listening_timestamp.start_time);
		formData.append('listening_timestamp[end_time]', details.listening_timestamp.end_time);
		formData.append('interview_type', details.interview_type);
		formData.append('desktop_intro_video', details.desktop_intro_video);
		formData.append('mobile_intro_video', details.mobile_intro_video);
		formData.append('is_new_format', details.is_new_format);

		// Handle appending questions array
		details.questions.forEach((question, index) => {
			formData.append(`questions[${index}][question_text]`, question.question_text);
			formData.append(`questions[${index}][is_last_question]`, question.is_last_question);
			formData.append(`questions[${index}][question_id]`, question.question_id);
			formData.append(`questions[${index}][start_time]`, question.start_time);
			formData.append(`questions[${index}][end_time]`, question.end_time);
			formData.append(`questions[${index}][skill_type]`, question.skill_type);
			formData.append(`questions[${index}][skip_evaluation]`, question.skip_evaluation);
			formData.append(`questions[${index}][skill]`, question.skill);
			formData.append(`questions[${index}][sub_skill]`, question.sub_skill.join(','));
			formData.append(`questions[${index}][definition]`, question.definition);
		});

		for (const [key, value] of formData.entries()) {
			console.log(`${key}:`, value);
		}

		const { data } = await axios.post(`${API_URL}/labs/mock-interview/new`, formData);
		console.log(data);
		if (data.success) {
			navigate('/mock-interviews/dashboard', {
				state: { refetch: true },
			});
			toast.success('Interview created successfully');
			setDetails({
				job_role: '',
				job_description: '',
				job_poster: '',
				language: '',
				interview_level: '',
				topicId: '',
				question_video_link: '',
				mobile_question_video_link: '',
				listening_timestamp: {
					start_time: null,
					end_time: null,
				},
				questions: [
					{
						question_text: '',
						is_last_question: false,
						question_id: 0,
						start_time: null,
						end_time: null,
						skill_type: '',
						skip_evaluation: false,
						skill: '',
						sub_skill: [],
						definition: '',
					},
				],
				weightage: [],
				interview_type: 'full_interview',
				desktop_intro_video: '',
				mobile_intro_video: '',
				is_new_format: true,
			});
			setActiveStep(0);
		} else {
			toast.error('Error Occurred, Try Again!!');
		}
	};

	const saveMockinterviewDetails = async () => {
		// Create a new FormData object
		const formData = new FormData();

		// Append fields to the FormData object
		formData.append('job_role', details.job_role);
		formData.append('job_description', details.job_description);
		formData.append('job_id', job_id);
		formData.append('job_poster', details.job_poster); // This is the image file
		formData.append('topicId', details.topicId);
		formData.append('interview_level', details.interview_level);
		formData.append('skills', details.skills.join(','));
		formData.append('language', details.language);

		for (const [key, value] of formData.entries()) {
			console.log(`${key}:`, value);
		}

		const { data } = await axios.post(`${API_URL}/labs/mock-interview/save`, formData);
		console.log(data);
		if (data.success) {
			navigate('/mock-interviews/dashboard', {
				state: { refetch: true },
			});
			toast.success('Interview saved successfully');
		} else {
			toast.error('Error Occurred, Try Again!!');
		}
	};

	const handleNext = async () => {
		if (activeStep === 0) {
			if (details.job_role === '') {
				toast.error('Job Role is required');
				return;
			}
			if (details.job_description === '') {
				toast.error('Job Description is required');
				return;
			}
			if (details.job_poster === '') {
				toast.error('Job Poster is required');
				return;
			}
			if (details.topicId === '') {
				toast.error('Topic is required');
				return;
			}
			if (details.interview_level === '') {
				toast.error('Interview Level is required');
				return;
			}
			if (details.skills.length === 0) {
				toast.error('Skills are required');
				return;
			}
		}
		// if (activeStep === 1) {
		// 	if (details.question_video_link === '') {
		// 		toast.error('Desktop Question Video is required');
		// 		return;
		// 	}
		// 	if (details.mobile_question_video_link === '') {
		// 		toast.error('Mobile Question Video is required');
		// 		return;
		// 	}
		// }
		if (activeStep === 2) {
			// for (let i = 0; i < details.questions.length; i++) {
			// 	if (details.questions[i].question_text === '') {
			// 		toast.error(`Question text for question ${i + 1} is required`);
			// 		return;
			// 	}
			// 	if (
			// 		!details.questions[i].is_last_question &&
			// 		!details.questions[i].skip_evaluation
			// 	) {
			// 		if (details.questions[i].skill_type === '') {
			// 			toast.error(`Skill type for question ${i + 1} is required`);
			// 			return;
			// 		}
			// 		if (details.questions[i].skill === '') {
			// 			toast.error(`Skill for question ${i + 1} is required`);
			// 			return;
			// 		}
			// 		if (details.questions[i].sub_skill.length === 0) {
			// 			toast.error(`Sub Skill for question ${i + 1} is required`);
			// 			return;
			// 		}
			// 		if (details.questions[i].definition === '') {
			// 			toast.error(`Definition for question ${i + 1} is required`);
			// 			return;
			// 		}
			// 	}
			// }
			let skills = [];
			for (let question of details.questions) {
				if (!question.is_last_question && !question.skip_evaluation) {
					if (!skills.includes(question.skill)) {
						skills.push(question.skill);
					}
				}
			}
			let weightage = [];
			for (let skill of skills) {
				weightage.push({
					skill: skill,
					value: 100 / skills.length,
				});
			}
			setDetails((prev) => ({
				...prev,
				weightage: weightage,
			}));
		}
		if (activeStep === 3) {
			let totalWeightage = new Decimal(0);
			for (let weightage of details.weightage) {
				totalWeightage = totalWeightage.plus(weightage.value);
			}
			const overallWeightage = new Decimal(totalWeightage.floor());
			if (overallWeightage.d[0] !== 100) {
				toast.error('Total weightage should be 100');
				return;
			}
		}
		// if (activeStep === 4) {
		// 	if (
		// 		details.listening_timestamp.start_time === null ||
		// 		details.listening_timestamp.end_time === null ||
		// 		details.listening_timestamp.start_time === '' ||
		// 		details.listening_timestamp.end_time === '' ||
		// 		isNaN(details.listening_timestamp.start_time) ||
		// 		isNaN(details.listening_timestamp.end_time)
		// 	) {
		// 		toast.error('Listening Timestamps are required');
		// 		return;
		// 	}
		// 	for (let i = 0; i < details.questions.length; i++) {
		// 		if (
		// 			details.questions[i].start_time === null ||
		// 			details.questions[i].end_time === null ||
		// 			details.questions[i].start_time === '' ||
		// 			details.questions[i].end_time === '' ||
		// 			isNaN(details.questions[i].start_time) ||
		// 			isNaN(details.questions[i].end_time)
		// 		) {
		// 			toast.error(`Timestamps for question ${i + 1} are required`);
		// 			return;
		// 		}
		// 	}
		// }
		if (activeStep === 5) {
			setLoading(true);
			if (type === 'edit') {
				updatePilot();
			} else {
				createPilot();
			}
			setLoading(false);
			return;
		}
		console.log(details);
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};
	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};
	const renderSteps = () => {
		switch (activeStep) {
			case 0:
				return <JobCreation details={details} setDetails={setDetails} />;
			case 1:
				return <Videos details={details} setDetails={setDetails} />;
			case 2:
				return <Questions details={details} setDetails={setDetails} />;
			case 3:
				return <SkillsWeightage details={details} setDetails={setDetails} />;
			case 4:
				return <Timestamps details={details} setDetails={setDetails} />;
			case 5:
				return <Review details={details} setDetails={setDetails} />;
			default:
				return <></>;
		}
	};

	useEffect(() => {
		if (type === 'edit') {
			setLoading(true);
			axios
				.get(`${API_URL}/labs/get-pilot/${pilot_id}`, {
					headers: {
						'Content-Type': 'application/json',
						authorization: `Bearer ${
							JSON?.parse(sessionStorage?.getItem('admin'))?.token
						}`,
					},
				})
				.then(({ data }) => {
					console.log(data);
					setLoading(false);
					if (data.success) {
						// Spread the current state and override with incoming data
						setDetails((prevDetails) => ({
							...prevDetails,
							...data.data,
						}));
					} else {
						toast.error('Error Occurred, Try Again!!');
					}
				});
		}
	}, [pilot_id, type]);

	useEffect(() => {
		if (type === 'edit') {
			setLoading(true);
			axios
				.get(`${API_URL}/labs/get-mock-interview/${job_id}`, {
					headers: {
						'Content-Type': 'application/json',
						authorization: `Bearer ${
							JSON?.parse(sessionStorage?.getItem('admin'))?.token
						}`,
					},
				})
				.then(({ data }) => {
					console.log(data);
					setLoading(false);
					if (data.success) {
						setDetails((prev) => ({
							...prev,
							job_role: data.data.job_role,
							job_description: data.data.job_description,
							job_poster: data.data.job_poster,
							topicId: data.data.topicId,
							interview_level: data.data.interview_level,
							skills: data.data.skills,
							language: data.data.language,
						}));
					} else {
						toast.error('Error Occurred, Try Again!!');
					}
				})
				.catch((e) => {
					console.log(e);
					toast.error('Error Occurred, Try Again!!');
				});
		}
	}, [job_id, type]);

	return (
		<>
			<Dialog open={loading}>
				<DialogContent>
					<DialogContentText>
						<Stack
							direction="row"
							spacing={2}
							justifyContent={'center'}
							alignItems={'center'}
						>
							<CircularProgress />
							<Typography>Please wait...</Typography>
						</Stack>
					</DialogContentText>
				</DialogContent>
			</Dialog>
			<Toaster />
			<Header />
			<Stack pt={3}>
				<Stepper activeStep={activeStep} alternativeLabel>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
			</Stack>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'center',
					alignItems: 'center',
					marginTop: '2rem',
					backgroundColor: '#ECF7FA',
				}}
			>
				<Card
					sx={{
						height: 'auto',
						// width: "500px",
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						justifyContent: 'center',
						padding: '30px',
						// backgroundColor: "#ECF7FA",
					}}
				>
					{renderSteps()}
				</Card>
			</Box>
			{activeStep < steps.length && (
				<Box
					sx={{
						display: 'flex',
						flexDirection: 'row',
						justifyContent: 'center',
						backgroundColor: '#ECF7FA',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexDirection: 'row',
							pt: 2,
							width: '30vw',
							backgroundColor: '#ECF7FA',
							paddingBottom: '50px',
							marginTop: '10px',
						}}
					>
						<Button
							disabled={activeStep === 0}
							onClick={handleBack}
							variant="outlined"
							sx={{
								textTransform: 'none',
								color: '#224C84',
								borderColor: '#224C84',
								'&:hover': {
									borderColor: '#224C84',
								},
								mr: 2,
							}}
						>
							Back
						</Button>
						<Button
							onClick={saveMockinterviewDetails}
							disabled={activeStep >= steps.length - 1}
							variant="contained"
							sx={{
								textTransform: 'none',
								backgroundColor: '#ffffff',
								flex: '1 1 auto',
								mr: 2,
								color: '#224C84',
								'&:hover': {
									backgroundColor: '#224C84',
									color: '#FFFFFF',
								},
							}}
						>
							Save Progress
						</Button>
						{/* <Box sx={{ flex: "1 1 auto" }} /> */}
						<Button
							onClick={handleNext}
							disabled={activeStep >= steps.length}
							variant="contained"
							sx={{
								textTransform: 'none',
								backgroundColor: '#224C84',
								'&:hover': {
									backgroundColor: '#224C84',
								},
							}}
						>
							{activeStep >= steps.length - 1
								? type === 'edit'
									? 'Save'
									: 'Create'
								: 'Next'}
						</Button>
					</Box>
				</Box>
			)}
		</>
	);
};

export default CreateMockInterviewQuestions;
