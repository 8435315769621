import {
	Box,
	Button,
	DialogContent,
	IconButton,
	Menu,
	MenuItem,
	Stack,
	Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { useNavigate } from 'react-router';
import Divider from '@mui/material/Divider';
import blue_logo from '../images/blue-logo.png';
import white_logo from '../images/white_logo.png';
import MenuIcon from '@mui/icons-material/Menu';
import bgimg from '../images/bg-img.png';
import bgimgmobile from '../images/bg-mobile.png';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import BusinessIcon from '@mui/icons-material/Business';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import videoUrl from '../images/Mock-interview-video.mp4';
import { API_URL } from '../../../global/constants';
import laptopImage from '../images/laptop-image.png';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import jwt_decode from 'jwt-decode';
import {
	GoogleOAuthProvider,
	googleLogout,
	useGoogleLogin,
	GoogleLogin,
} from '@react-oauth/google';
import Dialog from '@mui/material/Dialog';
import './mockinterview.css';
const HeroSection = () => {
	const isMobile = useMediaQuery('(max-width:600px)');
	const navigate = useNavigate();
	const isInViewport = (element) => {
		const rect = element.getBoundingClientRect();
		const windowHeight = window.innerHeight || document.documentElement.clientHeight;
		return rect.top < windowHeight && rect.bottom > windowHeight / 1.2;
	};
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [anchorEl2, setAnchorEl2] = React.useState(null);
	const [openLoginDialog, setOpenLoginDialog] = React.useState(false);
	const [openMockDialog, setOpenMockDialog] = useState(false);
	const [logo, setLogo] = useState(white_logo);
	const [bgColor, setBgColor] = useState('');
	const [bgImg, setBgImg] = useState('');
	const [textColor, setTextColor] = useState('#fff');
	const open = Boolean(anchorEl);
	const open2 = Boolean(anchorEl);
	const loginRef = React.useRef(null);

	const [showGoogleLogin, setShowGoogleLogin] = useState(false); // State to toggle visibility

	const handleTryNowClick = () => {
		setShowGoogleLogin(!showGoogleLogin); // Toggle Google login visibility
	};

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleClickScrollToLandingPage = (id) => {
		navigate('/');
		setTimeout(() => {
			window.location.hash = id;
		}, 0);
	};
	const handleClickScroll = (id) => {
		const element = document.getElementById(id);
		if (element) {
			// 👇 Will scroll smoothly to the top of the next section
			element.scrollIntoView({ behavior: 'smooth' });
		}
		handleClose();
	};
	const [modalOpen, setModalOpen] = React.useState(false);
	// const handleOpen = () => setOpen(true);
	// const handleClose = () => setOpen(false);

	useEffect(() => {
		const element = document.getElementById('mockinterview-main-page');
		window.addEventListener(
			'scroll',
			() => {
				if (!isInViewport(element)) {
					setLogo(blue_logo);
					setBgColor('#fff');
					setTextColor('#224C84');
				} else {
					setLogo(white_logo);
					setBgColor('');
					setTextColor('#fff');
				}
			},
			false
		);
	}, []);

	useEffect(() => {
		if (isMobile) {
			setBgImg(bgimgmobile);
		} else {
			setBgImg(bgimg);
		}
	}, [isMobile]);
	// const navigate = useNavigate();

	const responseOldMockInterview = async (response) => {
		console.log(response);
		var user_details = jwt_decode(response.credential);
		console.log(user_details);
		const first_name = user_details.given_name;
		const last_name = user_details.family_name;
		const email = user_details.email;
		const profile_image = user_details.picture;

		const { data } = await axios.post(`${API_URL}/labs/student/login`, {
			first_name,
			last_name,
			email,
			profile_image,
		});
		console.log(data, 'Data');
		console.log(data.success, 'data success');
		if (data.success) {
			toast.success(data.message);
			sessionStorage.setItem('student-auth-token', data.token);
			sessionStorage.setItem('student_interview_access', data.has_interview_access);
			sessionStorage.setItem('mock-interview', 'Yes');
			console.log('complete_profile', data.complete_profile);
			if (data.complete_profile) {
				navigate('/student/mock-interview/complete-profile', {
					state: {
						first_name,
						last_name,
						email,
						profile_image,
					},
				});
			} else {
				navigate('/student/mock-interview/dashboard');
			}
			sessionStorage.setItem('profile-image', profile_image);
		} else {
			toast.error(data.message);
		}
		// setProfile(user_details);
	};

	const responseMockInterview = async (response) => {
		console.log(response);
		const accessToken = response.access_token || response.credential;
		// var user_details = jwt_decode(response.access_token);
		const user_details = await fetchUserDetailsFromBackend(accessToken);

		console.log(user_details);
		const first_name = user_details.given_name;
		const last_name = user_details.family_name;
		const email = user_details.email;
		const profile_image = user_details.picture;

		const { data } = await axios.post(`${API_URL}/labs/student/login`, {
			first_name,
			last_name,
			email,
			profile_image,
		});
		console.log(data, 'Data');
		console.log(data.success, 'data success');
		if (data.success) {
			toast.success(data.message);
			sessionStorage.setItem('student-auth-token', data.token);
			sessionStorage.setItem('student_interview_access', data.has_interview_access);
			sessionStorage.setItem('mock-interview', 'Yes');
			console.log('complete_profile', data.complete_profile);
			if (data.complete_profile) {
				navigate('/student/mock-interview/complete-profile', {
					state: {
						first_name,
						last_name,
						email,
						profile_image,
					},
				});
			} else {
				navigate('/student/mock-interview/dashboard');
			}
			sessionStorage.setItem('profile-image', profile_image);
		} else {
			toast.error(data.message);
		}
		// setProfile(user_details);
	};

	const errorMessage = (error) => {
		console.log(error);
		toast.error('Something went wrong, Try Again!!');
	};

	const login = useGoogleLogin({
		onSuccess: (response) => {
			responseMockInterview(response); // Proceed with your logic
		},
		onError: errorMessage,
	});

	const fetchUserDetailsFromBackend = async (accessToken) => {
		try {
			// Replace '/api/auth/google' with your backend API endpoint
			const response = await fetch(`${API_URL}/labs/api/auth/google`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ token: accessToken }),
			});

			if (!response.ok) {
				throw new Error('Failed to fetch user details from backend');
			}

			// Parse the response from the backend
			const data = await response.json();
			return data; // This should return user details like name, email, etc.
		} catch (error) {
			console.error('Error in fetchUserDetailsFromBackend:', error.message);
			return null;
		}
	};

	return (
		<Stack
			id="mockinterview-main-page"
			height={isMobile ? '100vh' : '100vh'}
			// justifyContent={"center"}

			alignItems={'center'}
			style={{
				backgroundImage: `url(${bgImg})`,
				transition: 'all 1s ease-in-out',
				backgroundSize: 'cover',
				backgroundAttachment: 'fixed',
				backgroundPosition: 'center',
				backgroundRepeat: 'no-repeat',
				// opacity:0.8x
				position: 'relative',
			}}
		>
			<Toaster />
			<Dialog open={openMockDialog} onClose={() => setOpenMockDialog(false)}>
				<DialogContent>
					<GoogleLogin
						onSuccess={responseMockInterview}
						onError={errorMessage}
						// useOneTap
						// type="standard"
						// shape="pill"
					/>
				</DialogContent>
			</Dialog>
			<div
				style={{
					position: 'absolute',
					bottom: 0,
					left: 0,
					width: '100%',
					height: isMobile ? '10vh' : '10vh', // Adjust as needed
					background:
						'linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(49, 86, 134, 0.9) 100%)', // Adjusted gradient
					zIndex: 4,
				}}
			/>
			<Stack
				id="home"
				// bgcolor={"white"}
				// height={"100vh"}
				zIndex={100}
			>
				<Stack
					direction={'row'}
					justifyContent={'end'}
					p={1}
					spacing={1}
					position={'fixed'}
					top={0}
					right={0}
					width={'100vw'}
					zIndex={100}
					data-aos="fade-up"
					data-aos-duration="1000"
					data-aos-delay="500"
					// style={{ backdropFilter: "blur(5px)" }}
					bgcolor={bgColor}
				>
					<img
						src={logo}
						height={'40px'}
						alt="logo"
						style={{
							position: 'fixed',
							left: '40px',
							top: '15px',
						}}
					/>
					{isMobile && (
						<>
							<IconButton
								onClick={handleClick}
								size="large"
								style={{
									color: `${textColor}`,
								}}
								// sx={{ ml: 2 }}
							>
								<MenuIcon fontSize="medium" />
							</IconButton>
							<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
								<MenuItem
									onClick={() => navigate('/')}
									style={{
										color: '#224C84',
										fontWeight: 600,
									}}
								>
									{/* <ListItemIcon>
                                    <HomeIcon
                                        style={{
                                            color: "#fff",
                                            fontWeight: 600,
                                        }}
                                    />
                                </ListItemIcon> */}
									Home
								</MenuItem>
								<MenuItem
									onClick={() => handleClickScrollToLandingPage('benefits')}
									style={{
										color: '#224C84',
										fontWeight: 600,
									}}
								>
									{/* <ListItemIcon>
                                    <StarBorderPurple500Icon
                                        style={{
                                            color: "#fff",
                                            fontWeight: 600,
                                        }}
                                    />
                                </ListItemIcon> */}
									Benefits
								</MenuItem>
								<MenuItem
									onClick={() => handleClickScrollToLandingPage('testimonials')}
									style={{
										color: '#224C84',
										fontWeight: 600,
									}}
								>
									{/* <ListItemIcon>
                                    <MessageIcon
                                        style={{
                                            color: "#fff",
                                            fontWeight: 600,
                                        }}
                                    />
                                </ListItemIcon> */}
									Testimonials
								</MenuItem>
								<MenuItem
									onClick={() => handleClickScrollToLandingPage('product')}
									style={{
										color: '#224C84',
										fontWeight: 600,
									}}
								>
									{/* <ListItemIcon>
                                    <BoltIcon
                                        style={{
                                            color: "#fff",
                                            fontWeight: 600,
                                        }}
                                    />
                                </ListItemIcon> */}
									Product
								</MenuItem>
								<MenuItem
									onClick={() => handleClickScrollToLandingPage('features')}
									style={{
										color: '#224C84',
										fontWeight: 600,
									}}
								>
									{/* <ListItemIcon>
                                    <SettingsIcon
                                        style={{
                                            color: "#fff",
                                            fontWeight: 600,
                                        }}
                                    />
                                </ListItemIcon> */}
									Features
								</MenuItem>
								<MenuItem
									onClick={() => navigate('/contact')}
									style={{
										color: '#224C84',
										fontWeight: 600,
									}}
								>
									{/* <ListItemIcon>
                                    <EmailIcon
                                        style={{
                                            color: "#fff",
                                            fontWeight: 600,
                                        }}
                                    />
                                </ListItemIcon> */}
									Contact
								</MenuItem>
								<MenuItem
									onClick={() => navigate('/mock-interview')}
									style={{
										color: '#224C84',
										fontWeight: 600,
									}}
								>
									Mock Interviews
								</MenuItem>
								<Divider />
								<MenuItem onClick={() => setOpenMockDialog(true)}>
									<Stack>
										<Typography color="#224C84" fontWeight={600}>
											For Mock Interviews
										</Typography>
										<Typography fontSize={'0.9rem'} color="#cccccc">
											Practice mock interviews
										</Typography>
									</Stack>
								</MenuItem>
								<MenuItem onClick={() => navigate('/login/candidate')}>
									<Stack>
										<Typography color="#224C84" fontWeight={600}>
											For Company Interviews
										</Typography>
										<Typography fontSize={'0.9rem'} color="#cccccc">
											Give company interviews
										</Typography>
									</Stack>
								</MenuItem>

								<MenuItem
									onClick={() => navigate('/login/company')}
									style={{
										color: '#224C84',
										fontWeight: 600,
									}}
								>
									For Companies
								</MenuItem>
							</Menu>
						</>
					)}
					{!isMobile && (
						<>
							<Button
								onClick={() => navigate('/')}
								// startIcon={<HomeIcon />}
								style={{
									color: `${textColor}`,
									fontWeight: 600,
									textTransform: 'none',
								}}
							>
								Home
							</Button>
							<Button
								onClick={() => handleClickScrollToLandingPage('benefits')}
								// startIcon={<StarBorderPurple500Icon />}
								style={{
									color: `${textColor}`,
									fontWeight: 600,
									textTransform: 'none',
								}}
							>
								Benefits
							</Button>
							<Button
								onClick={() => handleClickScrollToLandingPage('testimonials')}
								// startIcon={<MessageIcon />}
								style={{
									color: `${textColor}`,
									fontWeight: 600,
									textTransform: 'none',
								}}
							>
								Testimonials
							</Button>
							<Button
								onClick={() => handleClickScrollToLandingPage('product')}
								// startIcon={<BoltIcon />}
								style={{
									color: `${textColor}`,
									fontWeight: 600,
									textTransform: 'none',
								}}
							>
								Product
							</Button>
							<Button
								onClick={() => handleClickScrollToLandingPage('features')}
								// startIcon={<SettingsIcon />}
								style={{
									color: `${textColor}`,
									fontWeight: 600,
									textTransform: 'none',
								}}
							>
								Features
							</Button>
							<Button
								onClick={() => navigate('/contact')}
								// startIcon={<EmailIcon />}
								style={{
									color: `${textColor}`,
									fontWeight: 600,
									textTransform: 'none',
								}}
							>
								Contact
							</Button>
							<Button
								onClick={() => navigate('/mock-interview')}
								style={{
									color: '#224C84',
									fontWeight: 600,
									textTransform: 'none',
									backgroundColor: '#FFC107',
									margin: '0 10px',
									padding: '5px 10px',
								}}
							>
								Mock Interviews
							</Button>
						</>
					)}
					{!isMobile && (
						<>
							{/* <Divider orientation="vertical" sx={{
                            height:"40px",
                            width:"3px"
                        }}/> */}
							<Button
								variant="outlined"
								size="large"
								// onClick={() => navigate("/login/candidate")}
								onClick={(event) => setAnchorEl2(event.currentTarget)}
								style={{
									// backgroundColor: "#D2ECF6",
									color: `${textColor}`,
									borderColor: `${textColor}`,
									border: '2px solid',
									borderRadius: '10px',
									textTransform: 'none',
									fontWeight: '600',
								}}
								startIcon={<PeopleOutlineIcon />}
							>
								For Interviewees
							</Button>

							<Menu
								id="menu-appbar"
								anchorEl={anchorEl2}
								open={Boolean(anchorEl2)}
								onClose={() => setAnchorEl2(null)}
							>
								{/* <MenuItem
								// onClick={() => {
								//     setOpenLoginDialog(true);
								// }}
								>
									<Stack justifyContent={'center'} alignItems={'left'} rowGap={2}>
										<Typography
											fontWeight={'bold'}
											color={'#224C84'}
											fontSize={'1rem'}
										>
											For Students
										</Typography>
										<GoogleLogin
											onSuccess={responseMessage}
											onError={errorMessage}
											// useOneTap
											// type="standard"
											// shape="pill"
										/>
										<Typography color={'#cccccc'} fontSize={'0.7rem'}>
											Practice mock interviews
										</Typography>
									</Stack>
								</MenuItem> */}
								{/* <Divider /> */}
								<MenuItem
								// onClick={() => {
								//     setOpenLoginDialog(true);
								// }}
								>
									<Stack justifyContent={'center'} alignItems={'left'} rowGap={2}>
										<Typography
											fontWeight={'bold'}
											color={'#224C84'}
											fontSize={'1rem'}
										>
											For Mock Interiews
										</Typography>
										<GoogleLogin
											onSuccess={responseOldMockInterview}
											onError={errorMessage}
											// useOneTap
											// type="standard"
											// shape="pill"
										/>
										<Typography color={'#cccccc'} fontSize={'0.7rem'}>
											Practice mock interviews
										</Typography>
									</Stack>
								</MenuItem>
								<Divider />
								<MenuItem onClick={() => navigate('/login/candidate')}>
									<Stack justifyContent={'center'} alignItems={'left'}>
										<Typography
											fontWeight={'bold'}
											color={'#224C84'}
											fontSize={'1rem'}
										>
											For Company Interviews
										</Typography>
										<Typography color={'#cccccc'} fontSize={'0.7rem'}>
											Give company interviews
										</Typography>
									</Stack>
								</MenuItem>
							</Menu>
							<Button
								variant="outlined"
								size="large"
								onClick={() => navigate('/login/company')}
								style={{
									color: `${textColor}`,
									borderColor: `${textColor}`,
									border: '2px solid',
									borderRadius: '10px',
									textTransform: 'none',
									fontWeight: '600',
								}}
								startIcon={<BusinessIcon />}
							>
								For Companies
							</Button>
						</>
					)}
				</Stack>
			</Stack>

			<Stack
				id="hero-section"
				justifyContent={'center'}
				alignItems={'center'}
				display={'flex'}
				flexDirection={isMobile ? 'column-reverse' : 'row'}
				position={'relative'}
				spacing={2}
				style={{
					margin: isMobile ? '20vw 2vw 13vw 2vw' : '7vw 4vw 4vw 4vw',
				}}
			>
				<div
					style={{
						maxWidth: isMobile ? '100%' : '40%',
						textAlign: isMobile ? 'center' : 'start',
						padding: isMobile ? '20px' : '20px 25px',
						marginLeft: 2,
					}}
				>
					<h1
						data-aos="fade-up"
						data-aos-duration="1000"
						data-aos-delay="500"
						style={{
							marginLeft: 2,
							marginRight: 2,
							marginBottom: 0,
							marginTop: 0,
							zIndex: 2,
							color: '#fff',
							fontWeight: 900,
							fontSize: isMobile ? '1.9rem' : '2.5rem',
							textAlign: isMobile ? 'center' : 'start',
						}}
					>
						Boost your confidence with AI powered mock interviews
					</h1>
					<p
						data-aos="fade-up"
						data-aos-duration="1200"
						data-aos-delay="500"
						fontSize={{ sm: '1.4rem', xs: '1.2rem' }}
						color={'#fff'}
						fontWeight={'300'}
						fontFamily={'Poppins'}
						textAlign={'center'}
						// width={"1000px"}
						ml={2}
						mr={2}
						pl={2}
						pr={4}
						style={{
							color: '#fff',
							paddingRight: '10px',
						}}
					>
						Prepare for your next big interview with AI.HYR’s mock interviews. Get
						real-time feedback on your performance and improve before it counts
					</p>
					<div
						className="container"
						style={{
							marginTop: isMobile ? '40px' : '45px',
							justifyContent: isMobile ? 'center' : 'start',
						}}
						data-aos="fade-up"
						data-aos-duration="1400"
						data-aos-delay="500"
					>
						<div className="bg"></div>
						<Button
							size="large"
							className="play-btn"
							// startIcon={<PlayCircleOutlineIcon />}
							onClick={() => login()}
							sx={{
								width: '300px',
								height: '50px',
								fontFamily: 'Poppins',
								textTransform: 'none',
								position: 'relative',
								zIndex: 10,
								// backgroundColor: "#224C84",
								backgroundColor: '#FFC107',
								fontSize: '1.2rem',
								color: '#224C84',
								borderRadius: '10px',
								'&:hover': {
									backgroundColor: '#FFC107',
									color: '#224C84',
								},
							}}
						>
							Try Now
						</Button>
					</div>
				</div>
				<div
					id="hero-video"
					style={{
						maxWidth: isMobile ? '90%' : '100%',
						height: '100%',
						position: 'relative',
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'start',
					}}
				>
					<video
						id="mobile-video"
						width={'100%'}
						style={{
							borderRadius: '20px',
							backgroundColor: 'white', // Adding a white frame
							padding: '8px', // Padding around the video for the frame effect
							boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)', // Subtle shadow for depth
						}}
						loop
						controls
						controlsList="nodownload" // Optional: Disable download button
					>
						<source src={videoUrl} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</div>

				<IconButton
					size="large"
					zIndex={200}
					// variant="outlined"
					// startIcon={}
					// mt="100px"
					// data-aos="fade"
					// data-aos-duration="1600"
					// data-aos-delay="500"
					onClick={() => handleClickScroll('calltoaction')}
					className="down-arrow"
					style={{
						position: 'absolute',
						marginTop: `${isMobile ? '550px' : '520px'}`,
					}}
					sx={{
						fontFamily: 'Poppins',
						display: isMobile ? 'none' : '',
						textTransform: 'none',
						position: 'absolute',
						color: '#FFC107',
						fontSize: '1.3rem',
						zIndex: '20',
					}}
				>
					<KeyboardDoubleArrowDownIcon fontSize="large" />
				</IconButton>
			</Stack>
		</Stack>
	);
};

export default HeroSection;
