import React, { useEffect, useRef, useState } from 'react';
import {
	Box,
	Paper,
	Button,
	IconButton,
	Skeleton,
	List,
	ListItem,
	Stack,
	TextareaAutosize,
	Typography,
} from '@mui/material';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import { Toaster, toast } from 'react-hot-toast';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import * as yup from 'yup';
import { useParams } from 'react-router';
import axios from 'axios';
import { API_URL } from '../../../global/constants';
import { Country, State } from 'country-state-city';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import Tooltip from '@mui/material/Tooltip';
import { useNavigate } from 'react-router-dom';
const isEmpty = (obj) => {
	if (!obj) {
		return true;
	}
	return Object.keys(obj).length === 0;
};

const validationSchema = yup.object().shape({
	about: yup.string().required('About section is required'),
	rolesandresponsibilities: yup.array().of(yup.string().required('Role is required')),
	educations: yup.array().of(yup.string().required('Education is required')),
	certifications: yup.array().of(yup.string().required('Certification is required')),
	additionalskills: yup.array().of(yup.string().required('Additional skill is required')),
});

export default function GeneratedDetails({ state, generatedJd }) {
	const [loading, setLoading] = useState(true);
	const inputRefs = useRef([]);
	const navigate = useNavigate();

	const formik = useFormik({
		initialValues: {
			_id: '',
			about: '',
			rolesandresponsibilities: [''],
			educations: [''],
			certifications: [null],
			skills: [''],
			additionalskills: [''],
			stateName: '',
			city: '',
			country: '',
			experience: { min: '', max: '' },
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			console.log(values, 'save called');
			await axios.put(`${API_URL}/jobdescription/update`, { values });
		},
	});

	const { id } = useParams();
	console.log(id, 'params id');
	useEffect(() => {
		async function fetchdetails() {
			const data = await axios.get(`${API_URL}/jobdescription/getdetails/${id}`);
			const jobdescription = data.data.jobdescription;
			const {
				about,
				rolesandresponsibilities,
				educations,
				certifications,
				skills,
				additionalskills,
				experience,
				_id,
			} = jobdescription;
			const newgenerated = {
				_id: _id,
				about: about.content,
				rolesandresponsibilities: rolesandresponsibilities.content,
				educations: educations.content,
				certifications: certifications.content,
				skills: skills.content,
				additionalskills: additionalskills.content,
				experience: experience,
			};
			formik.setValues(newgenerated);
		}
		if (id) {
			fetchdetails();
		}
	}, [id]);

	useEffect(() => {
		if (generatedJd) {
			console.log(generatedJd, 'genjd');
			formik.setValues(generatedJd);
			setLoading(false);
		}
	}, [generatedJd]);

	useEffect(() => {
		if (state?.experience) {
			formik.setFieldValue('experience', state.experience);
		}
	}, [state.experience]);

	const copyToClipboard = () => {
		let texttocopy = '';
		for (let key in formik.values) {
			if (key === '_id') {
				continue;
			}

			if (formik.values.hasOwnProperty(key)) {
				if (formik.values[key].length != 0) {
					texttocopy += key + '\n';
					texttocopy += formik.values[key] + '\n';
				}
			}
		}

		navigator.clipboard
			.writeText(texttocopy)
			.then(() => {
				toast.success('Copied to clipboard');
			})
			.catch((err) => {
				toast.error('Failed to copy text: ' + err);
			});
	};

	const downloadAsWord = () => {
		const content = `
            <html xmlns:w="urn:schemas-microsoft-com:office:word">
            <head><meta charset="utf-8"></head>
            <body>${document.getElementById('paper-content').innerHTML}</body>
            </html>
        `;

		const blob = new Blob(['\ufeff', content], { type: 'application/msword' });
		const url = URL.createObjectURL(blob);

		const link = document.createElement('a');
		link.href = url;
		link.setAttribute('download', 'jobdescription.doc');
		document.body.appendChild(link);

		link.click();
		document.body.removeChild(link);
	};

	const insertAfterIndex = (array, index, newItem) => {
		return [...array.slice(0, index + 1), newItem, ...array.slice(index + 1)];
	};

	const handleKeyDown = (push, remove, index, value, event) => {
		if (event.key === 'Enter') {
			event.preventDefault();
			console.log(event.target.name, 'etn');
			formik.setFieldValue(
				event.target.name.split('.')[0],
				insertAfterIndex(formik.values[event.target.name.split('.')[0]], index, '')
			);
			// setTimeout(() => {
			// 	const nextElement = inputRefs.current[index + 1];
			// 	if (nextElement) {
			// 		nextElement.focus();
			// 	}
			// }, 100);
		} else if (event.key === 'Backspace' && value === '') {
			remove(index);
			// setTimeout(() => {
			// 	const prevElement = inputRefs.current[index - 1];
			// 	if (prevElement) {
			// 		prevElement.focus();
			// 	}
			// }, 100);
		}
	};

	const [isModalOpen, setIsModalOpen] = useState(false);
	const [unsavedChanges, setUnsavedChanges] = useState(true);
	const handlesaveandclose = async (type) => {
		const values = {
			...formik.values,
			experience: state.experience,
			country: state.country,
			stateName: state.stateName,
			city: state.city,
		};
		await axios.put(`${API_URL}/jobdescription/update`, { values });
		toast.success('Details saved successfully');
		setTimeout(() => {
			navigate(`/dashboard/jobslist/company`);
		}, 1000);
	};
	const handlesave = async (type) => {
		const values = formik.values;
		await axios.put(`${API_URL}/jobdescription/update`, { values });
		toast.success('Details saved successfully');
	};

	useEffect(() => {
		const handleBeforeUnload = (event) => {
			if (unsavedChanges) {
				event.preventDefault();
				event.returnValue = '';
				setIsModalOpen(true);
			}
		};

		window.addEventListener('beforeunload', handleBeforeUnload);

		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
		};
	}, [unsavedChanges]);

	if (loading) {
		return <>Loading...</>;
	}
	if (isEmpty(formik.values)) {
		return (
			<Box
				style={{
					display: 'flex',
					flexDirection: 'column',
					gap: '4px',
				}}
				onSubmit={formik.handleSubmit}
			>
				<Paper
					id="paper-content"
					sx={{
						p: 6,
						backgroundColor: 'white',
						borderRadius: '8px',
						boxShadow: 3,
						position: 'relative',
					}}
				>
					<h3>Job Description</h3>
					{[1, 2, 3, 4].map((val) => (
						<Skeleton key={val} />
					))}
					<h3>Roles and Responsibilities</h3>
					{[1, 2, 3, 4].map((val) => (
						<Skeleton key={val} />
					))}
					<h3>Education</h3>
					{[1, 2, 3, 4].map((val) => (
						<Skeleton key={val} />
					))}
					<h3>Experience Required</h3>
					{[1, 2, 3, 4].map((val) => (
						<Skeleton key={val} />
					))}
					<h3>Skills</h3>
					{[1, 2, 3, 4].map((val) => (
						<Skeleton key={val} />
					))}
					<h3>Good to have</h3>
					{[1, 2, 3, 4].map((val) => (
						<Skeleton key={val} />
					))}
				</Paper>
			</Box>
		);
	}
	console.log(formik.values, 'formik values');

	return (
		<Box
			sx={{
				display: 'flex',
				flexWrap: 'wrap',
				'& > :not(style)': {
					m: 1,
					width: '100%',
					height: 'auto',
				},
			}}
		>
			<Toaster />

			<Paper
				id="paper-content"
				sx={{
					p: 6,
					backgroundColor: 'white',
					borderRadius: '8px',
					boxShadow: 3,
					position: 'relative',
				}}
			>
				<h3>{state['position'] ? state['position'].toUpperCase() : 'Job Description'}</h3>
				<FormikProvider value={formik}>
					<form
						style={{
							display: 'flex',
							flexDirection: 'column',
							gap: '4px',
						}}
						onSubmit={formik.handleSubmit}
					>
						<TextareaAutosize
							name="about"
							value={formik.values.about}
							onChange={formik.handleChange}
							className="editable-paragraph"
							style={{
								width: '100%',
								resize: 'none',
								border: 'none',
								outline: 'none',
								lineHeight: '20px',
							}}
						/>
						<h3>Roles and Responsibilites</h3>
						<FieldArray name="rolesandresponsibilities">
							{({ push, remove }) => (
								<List>
									{formik.values.rolesandresponsibilities?.map((role, index) => (
										<ListItem
											key={index}
											alignItems="flex-start"
											sx={{ margin: 0, padding: '4px 0' }}
										>
											<Stack
												direction="row"
												spacing={2}
												alignItems="center"
												sx={{ width: '100%' }}
											>
												<div className="dot" />
												<TextareaAutosize
													name={`rolesandresponsibilities.${index}`}
													value={role}
													onChange={formik.handleChange}
													onKeyDown={(event) =>
														handleKeyDown(
															push,
															remove,
															index,
															role,
															event
														)
													}
													className="editable-paragraph"
													style={{
														width: '100%',
														resize: 'none',
														border: 'none',
														outline: 'none',
														lineHeight: '20px',
													}}
													ref={(el) => (inputRefs.current[index] = el)}
												/>
											</Stack>
										</ListItem>
									))}
								</List>
							)}
						</FieldArray>
						<h3>Education</h3>

						<FieldArray name="educations">
							{({ push, remove }) => (
								<List>
									{formik.values.educations?.map((education, index) => (
										<ListItem
											key={index}
											alignItems="flex-start"
											sx={{ margin: 0, padding: '4px 0' }}
										>
											<Stack
												direction="row"
												spacing={2}
												alignItems="center"
												sx={{ width: '100%' }}
											>
												<div className="dot" />
												<TextareaAutosize
													name={`educations.${index}`}
													value={education}
													onChange={formik.handleChange}
													onKeyDown={(event) =>
														handleKeyDown(
															push,
															remove,
															index,
															education,
															event
														)
													}
													className="editable-paragraph"
													style={{
														width: '100%',
														resize: 'none',
														border: 'none',
														outline: 'none',
														lineHeight: '20px',
													}}
													ref={(el) => (inputRefs.current[index] = el)}
												/>
											</Stack>
										</ListItem>
									))}
								</List>
							)}
						</FieldArray>
						<h3>Experience Required</h3>
						<Typography
							component="div"
							style={{
								width: '100%',
								lineHeight: '20px',
								border: 'none',
								outline: 'none',
								resize: 'none',
								backgroundColor: 'transparent',
								padding: '8px',
								whiteSpace: 'pre-wrap', // Preserves whitespace and line breaks
							}}
						>
							<>{`${state?.experience?.min} to ${state?.experience?.max} Years`}</>
						</Typography>
						{formik.values.certifications?.length > 0 && <h3>Certifications</h3>}
						<FieldArray name="certifications">
							{({ push, remove }) => (
								<List>
									{formik.values.certifications?.map((certification, index) => (
										<ListItem
											key={index}
											alignItems="flex-start"
											sx={{ margin: 0, padding: '4px 0' }}
										>
											<Stack
												direction="row"
												spacing={2}
												alignItems="center"
												sx={{ width: '100%' }}
											>
												<div className="dot" />
												<TextareaAutosize
													name={`certifications.${index}`}
													value={certification}
													onChange={formik.handleChange}
													onKeyDown={(event) =>
														handleKeyDown(
															push,
															remove,
															index,
															certification,
															event
														)
													}
													className="editable-paragraph"
													style={{
														width: '100%',
														resize: 'none',
														border: 'none',
														outline: 'none',
														lineHeight: '20px',
													}}
													ref={(el) => (inputRefs.current[index] = el)}
												/>
											</Stack>
										</ListItem>
									))}
								</List>
							)}
						</FieldArray>

						<h3>Skills</h3>
						<FieldArray
							sx={{
								marginBottom: '0px',
								padding: '0px',
							}}
							name="skills"
						>
							{({ push, remove }) => (
								<List>
									{formik.values.skills?.map((skill, index) => (
										<ListItem
											key={index}
											alignItems="flex-start"
											sx={{ margin: 0, padding: '4px 0' }}
										>
											<Stack
												direction="row"
												spacing={2}
												alignItems="center"
												sx={{ width: '100%' }}
											>
												<div className="dot" />
												<TextareaAutosize
													name={`skills.${index}`}
													value={skill}
													onChange={formik.handleChange}
													onKeyDown={(event) =>
														handleKeyDown(
															push,
															remove,
															index,
															skill,
															event
														)
													}
													className="editable-paragraph"
													style={{
														width: '100%',
														resize: 'none',
														border: 'none',
														outline: 'none',
														lineHeight: '20px',
													}}
													ref={(el) => (inputRefs.current[index] = el)}
												/>
											</Stack>
										</ListItem>
									))}
								</List>
							)}
						</FieldArray>
						<h3>Good to Have</h3>
						<FieldArray
							sx={{
								marginTop: '0px',
							}}
							name="additionalskills"
						>
							{({ push, remove }) => (
								<List>
									{formik.values.additionalskills?.map(
										(additionalskill, index) => (
											<ListItem
												key={index}
												alignItems="flex-start"
												sx={{ margin: 0, padding: '4px 0' }}
											>
												<Stack
													direction="row"
													spacing={2}
													alignItems="center"
													sx={{ width: '100%' }}
												>
													<div className="dot" />
													<TextareaAutosize
														name={`additionalskills.${index}`}
														value={additionalskill}
														onChange={formik.handleChange}
														onKeyDown={(event) =>
															handleKeyDown(
																push,
																remove,
																index,
																additionalskill,
																event
															)
														}
														className="editable-paragraph"
														style={{
															width: '100%',
															resize: 'none',
															border: 'none',
															outline: 'none',
															lineHeight: '20px',
														}}
														ref={(el) =>
															(inputRefs.current[index] = el)
														}
													/>
												</Stack>
											</ListItem>
										)
									)}
								</List>
							)}
						</FieldArray>

						{state.city && (
							<>
								<h3>Job Location</h3>
								<TextareaAutosize
									value={
										state.city +
										',' +
										State.getStateByCodeAndCountry(
											state['stateName'],
											state['country']
										)?.name +
										',' +
										Country.getCountryByCode(state['country'])?.name
									}
									className="editable-paragraph"
									style={{
										width: '100%',
										resize: 'none',
										border: 'none',
										outline: 'none',
										lineHeight: '20px',
										marginLeft: '10px',
									}}
								/>
							</>
						)}
						{state.compensation !== '' && (
							<>
								<h3 style={{ marginTop: '5px' }}>Compensation</h3>
								<Typography
									component="div"
									contentEditable
									suppressContentEditableWarning
									style={{
										width: '100%',
										lineHeight: '20px',
										border: 'none',
										outline: 'none',
										resize: 'none',
										backgroundColor: 'transparent',
										padding: '8px',
										whiteSpace: 'pre-wrap', // Preserves whitespace and line breaks
									}}
								>
									{state.compensation} Lakhs Per Annum
								</Typography>
							</>
						)}
						<Box sx={{ position: 'absolute', right: '20px', top: '10px' }}>
							<Tooltip title="Copy the file" arrow>
								<IconButton onClick={copyToClipboard} aria-label="copy">
									<FileCopyIcon color="primary" fontSize="medium" />
								</IconButton>
							</Tooltip>
							<Tooltip title="Download the file" arrow>
								<IconButton onClick={downloadAsWord} aria-label="copy">
									<DownloadForOfflineIcon color="primary" fontSize="medium" />
								</IconButton>
							</Tooltip>

							<Button
								sx={{
									marginLeft: '4px',
									px: '6px',
									py: '2px',
								}}
								type="submit"
								onClick={handlesaveandclose}
								variant="contained"
								color="primary"
								style={{
									fontSize: '12px',
								}}
							>
								Save & Close
							</Button>
						</Box>
					</form>
				</FormikProvider>
			</Paper>
		</Box>
	);
}
