import React, { useState, useEffect, useRef, useCallback, useContext } from 'react';
import ReactPlayer from 'react-player';
import { AudioRecorder } from '../AudioRecorder';
import { useLocation } from 'react-router';
import { Header } from '../shared/Header';
import axios from 'axios';
import { API_URL } from '../../global/constants';
import { CircularStatic } from '../CircularProgressWithLabel';
import { QuestionPopper } from '../QuestionPopper';
import { CountdownTimer } from '../CountdownTimer';
import { VideoAndScreenRecorder } from '../VideoAndScreenRecorder';
import { BrowserView, MobileView } from 'react-device-detect';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Stack } from '@mui/material';
import { ReplayButton } from '../ReplayButton';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

// Dialog to prevent user from leaving the page
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { InterviewContext } from '../../global/InterviewContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectInterviewData } from '../../features/interview/interview';
import { PROCTORING_TOOL } from '../../global/constants';
const datetostring = (currentDate) => {
	const year = currentDate.getFullYear();
	const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
	const day = String(currentDate.getDate()).padStart(2, '0');
	const hours = String(currentDate.getHours()).padStart(2, '0');
	const minutes = String(currentDate.getMinutes()).padStart(2, '0');
	const seconds = String(currentDate.getSeconds()).padStart(2, '0');

	// Construct the date-time string
	const dateTimeString = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
	return dateTimeString;
};

export const InterviewTrial = () => {
	const navigate = useNavigate();

	const Interview_started_time = new Date();
	const { setInterviewEnded } = useContext(InterviewContext);
	const interview_data = useSelector(selectInterviewData);
	const { fullName, isDemo, isStudentInterview, candidate_interview, proctoring_token } =
		interview_data;
	const { proctoring_tool, transcribe_tool } = candidate_interview;
	const [isproctoringCheck, setisproctoringcheck] = useState(false);
	const reactPlayerRef = useRef(null);
	const introVideoRef = useRef(null);
	const [error, setError] = useState(false);
	const [videoUrl, setVideoUrl] = useState('');
	const [mobileVideoUrl, setMobileVideoUrl] = useState('');
	const [mobileIntroVideo, setMobileIntroVideo] = useState('');
	const [desktopIntroVideo, setDesktopIntroVideo] = useState('');
	const [hasIntroVideoEnded, setHasIntroVideoEnded] = useState(false);
	const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
	const [isListening, setIsListening] = useState(false);
	const [isRecording, setIsRecording] = useState(false);
	const { interviewtype } = useParams();

	const { state } = useLocation();
	console.log(state, 'interview state');

	const { _id: interviewKey } = candidate_interview;
	const [questionTimestamps, setQuestionTimestamps] = useState([]);
	const [loading, setLoading] = useState(true);
	const [hasInterviewEnded, setHasInterviewEnded] = useState(false);
	const [listeningTimestamp, setListeningTimestamp] = useState({
		start_time: 0,
		end_time: 0,
	});
	const [start, setStart] = useState(false);
	const [questionsArray, setQuestionsArray] = useState([]);

	const [open, setOpen] = useState(true);
	const [stopInterview, setStopInterview] = useState(false);

	const handleClose = () => setOpen(false);

	const [playing, setPlaying] = useState(false);
	const [playsinline, setPlaysinline] = useState(false);
	const [isIphone, setIsIphone] = useState(false);
	const [showReplayButton, setShowReplayButton] = useState(false);
	const [interviewStartedTime, setInterviewStartedTime] = useState(() => new Date());
	const [answeringTime, setAnsweringTime] = useState('');
	const [showCountdownTimer, setShowCountdownTimer] = useState(false);
	const [desktopPlaying, setDesktopPlaying] = useState(true);
	const [desktopPlaysinline, setDesktopPlaysinline] = useState(true);
	const [questionHints, setQuestionHints] = useState([]);
	// For preventing user from leaving the page
	const [alertDialogOpen, setAlertDialogOpen] = useState(false);
	// const [tabSwitches, setTabSwitches] = useState(0);
	let tabSwitches = 0;
	let timer;

	useEffect(() => {
		if (alertDialogOpen) {
			timer = setInterval(() => {
				endUserInterview('violation', tabSwitches);
			}, [15000]);
		} else {
			clearInterval(timer);
		}
		return () => clearInterval(timer);
	}, [alertDialogOpen]);

	const exitRefresh = useCallback((e) => {
		e.preventDefault();
		e.returnValue = '';
	});
	const checkTabSwitches = useCallback(() => {
		if (!hasInterviewEnded && proctoring_tool !== PROCTORING_TOOL) {
			setAlertDialogOpen(true);
			setPlaying(false);
			setPlaysinline(false);
			setDesktopPlaying(false);
			setDesktopPlaysinline(false);
			tabSwitches++;
			if (tabSwitches > 3) {
				setAlertDialogOpen(false);
				// console.log("You have switched tabs more than 3 times.");
				if (
					!isStudentInterview &&
					!proctoring_token &&
					proctoring_tool !== PROCTORING_TOOL
				) {
					alert('You violated the terms of the interview. The interview has been ended.');
					endUserInterview();
					setAlertDialogOpen(false);
				}
			}
		}
	});
	useEffect(() => {
		console.log(proctoring_tool);
		if (proctoring_token) {
			console.log('loading ...');
			const jwtToken = proctoring_token;
			window.remoteProctoring
				.remoteProctorInitV2({ authToken: jwtToken })
				.then(function () {
					console.log('Initialization is success');
					setisproctoringcheck(true);
				})
				.catch(function (err) {
					console.log('Initialization failed', err);
					alert('Initialization failed');
				});

			console.log('loaded');
		}
	}, [proctoring_token]);
	useEffect(() => {
		// if (!videoUploaded) {
		//     window.addEventListener("beforeunload", exitRefresh);
		// } else {
		//     window.removeEventListener("beforeunload", exitRefresh);
		// }

		if (proctoring_tool === PROCTORING_TOOL) {
			window.remoteProctoring
				.stop()
				.then(function () {
					navigate(`/end/${fullName}/${interviewKey}`, {
						state: {
							isDemo: false,
							isStudentInterview,
						},
					});
					console.log('Remote Proctoring session ended successfully');
				})
				.catch(function (err) {
					console.log('Remote Proctoring session end FAILED', err);
				});
		} else {
			if (hasInterviewEnded && !loading) {
				console.log(hasInterviewEnded, 'xyz has interview ended ', loading);
				navigate(`/end/${fullName}/${interviewKey}`);
			}
			if (!hasInterviewEnded) {
				window.addEventListener('blur', checkTabSwitches);
			} else {
				window.removeEventListener('blur', checkTabSwitches);
			}
			return () => {
				window.removeEventListener('beforeunload', exitRefresh);
				window.removeEventListener('blur', checkTabSwitches);
			};
		}
	}, [hasInterviewEnded]);

	useEffect(() => {
		if (
			navigator.platform === 'iPad' ||
			navigator.platform === 'iPhone' ||
			navigator.platform === 'iPod'
		) {
			console.log('This is an iOS device.');
			setIsIphone(false);
		} else {
			console.log('This is not an iOS device!');
			setIsIphone(false);
		}
	}, []);

	const restartPlaying = () => {
		setPlaying(true);
		setPlaysinline(true);
		setDesktopPlaying(true);
		setDesktopPlaysinline(true);
		setAlertDialogOpen(false);
		clearInterval(timer);
	};
	useEffect(() => {
		if (mobileIntroVideo && desktopIntroVideo && introVideoRef && introVideoRef.current) {
			introVideoRef.current.playing = true;
			introVideoRef.current.playsinline = true;
			introVideoRef.current.pip = false;
		}
	}, [mobileIntroVideo, desktopIntroVideo]);

	useEffect(() => {
		fetchQuestions(interviewKey);
	}, [interviewKey]);

	useEffect(() => {
		if (reactPlayerRef && reactPlayerRef.current) {
			reactPlayerRef.current?.seekTo(
				questionTimestamps[currentQuestionIndex].startTime,
				'seconds'
			);
			setIsRecording(false);
			reactPlayerRef.current.playing = true;
			reactPlayerRef.current.playsinline = true;
			reactPlayerRef.current.pip = false;
		}
		if (currentQuestionIndex === questionTimestamps.length - 1) {
			updateInterviewEnded();
		}
	}, [currentQuestionIndex]);

	const endUserInterview = async (endInterviewReason, tab_switches) => {
		if (sessionStorage.getItem('mock-interview')) {
			await axios.post(`${API_URL}/labs/mock-interview/end`, {
				interview_key: interviewKey,
				end_interview_reason: endInterviewReason,
				tab_switches,
			});
		} else {
			await axios.post(`${API_URL}/labs/interview/end`, {
				interview_key: interviewKey,
				end_interview_reason: endInterviewReason,
				tab_switches,
			});
		}
		setPlaying(false);
		setPlaysinline(false);
		setDesktopPlaying(false);
		setDesktopPlaysinline(false);
		setHasInterviewEnded(true);
		setInterviewEnded(true);
		// setUploadVideo(true);
	};

	const fetchQuestions = async (key) => {
		console.log('Interview key -- ', key);

		setLoading(true);
		let res = '';
		if (sessionStorage.getItem('mock-interview')) {
			res = await axios.get(`${API_URL}/labs/mock-interview/questions/${key}`);
		} else {
			res = await axios.get(`${API_URL}/labs/questions?interview_key=${key}`);
		}
		console.log(res, 'xyz result questions');
		setQuestionTimestamps(res?.data?.data?.timestamps);
		setVideoUrl(res.data.data.video_link);
		setMobileVideoUrl(res.data.data.mobile_video_link);
		setListeningTimestamp(res.data.data.listening_timestamp);
		setMobileIntroVideo(res?.data?.data?.mobile_intro_video);
		setDesktopIntroVideo(res?.data?.data?.desktop_intro_video);
		setQuestionsArray(res?.data?.data?.questions);
		setQuestionHints(res?.data?.data?.question_hints);
		// const questions = res.data.data.
		console.log(res.data.data);
		if (
			!res?.data?.data?.mobile_intro_video ||
			!res?.data?.data?.desktop_intro_video ||
			res?.data?.data?.mobile_intro_video === '' ||
			res?.data?.data?.desktop_intro_video === ''
		) {
			setHasIntroVideoEnded(true);
			setShowCountdownTimer(true);
		}
		if (res.data.data.start_with !== 0) {
			setHasIntroVideoEnded(true);
			setShowCountdownTimer(true);
		}
		setLoading(false);
		console.log(res.data.data.start_with, 'start with');
		setCurrentQuestionIndex(res.data.data.start_with);
		reactPlayerRef.current?.seekTo(questionTimestamps[res.data.data.start_with].startTime);
	};

	const handlePostQuestionAction = () => {
		if (!isListening && currentQuestionIndex < questionTimestamps.length - 1) {
			setIsListening(true);
			handleListeningLoop();
		}
	};

	const handleProgress = ({ playedSeconds }) => {
		if (!start) {
			reactPlayerRef.current?.seekTo(questionTimestamps[currentQuestionIndex].startTime);
			setStart(true);
		}
		if (
			!isListening &&
			currentQuestionIndex !== questionTimestamps.length - 1 &&
			playedSeconds >= questionTimestamps[currentQuestionIndex].endTime
		) {
			setIsRecording(true);
			setShowReplayButton(true);
			handlePostQuestionAction();
		}

		if (
			currentQuestionIndex >= questionTimestamps.length - 1 &&
			playedSeconds >= questionTimestamps[currentQuestionIndex].startTime &&
			!updateAnswerToDatabase
		) {
			setIsListening(false);
			setIsRecording(false);
			setShowReplayButton(false);
		}
		if (
			currentQuestionIndex === questionTimestamps.length - 1 &&
			playedSeconds >= questionTimestamps[currentQuestionIndex].endTime
		) {
			setHasInterviewEnded(true);
			setInterviewEnded(true);
			setIsListening(false);
			setIsRecording(false);
		}

		if (isListening && playedSeconds >= listeningTimestamp.end_time) {
			reactPlayerRef.current?.seekTo(listeningTimestamp.start_time, 'seconds');
		}
	};

	const handleEnded = () => {
		if (currentQuestionIndex < questionTimestamps.length - 1) {
			handlePostQuestionAction();
		} else {
			setHasInterviewEnded(true);
			setInterviewEnded(true);
			// setUploadVideo(true);
		}
	};

	const handleListeningLoop = () => {
		reactPlayerRef.current?.seekTo(listeningTimestamp.start_time, 'seconds');
	};

	const goToNextQuestion = () => {
		if (currentQuestionIndex >= questionTimestamps.length - 1) {
			setHasInterviewEnded(true);
			setInterviewEnded(true);
			// setUploadVideo(true);
			updateInterviewEnded();
			setIsListening(false);
			setIsRecording(false);
		} else {
			setCurrentQuestionIndex((prevIndex) => prevIndex + 1);
		}
	};

	const updateIsListening = (value) => {
		setShowReplayButton(false);
		setIsListening(value);
		if (value) {
			handleListeningLoop();
		} else {
			goToNextQuestion();
		}
	};
	const updateInterviewEnded = async () => {
		if (sessionStorage.getItem('mock-interview')) {
			await axios.post(`${API_URL}/labs/mock-interview/end`, {
				interview_key: interviewKey,
				tabSwitches,
			});
		} else {
			await axios.post(`${API_URL}/labs/interview/end`, {
				interview_key: interviewKey,
				tabSwitches,
			});
		}
	};

	const updateAnswerToDatabase = async (s3FileName, file) => {
		try {
			const formData = new FormData();
			if (transcribe_tool === 'bhashini') {
				// Convert audio to required 16kHz WAV format
				const wavFile = await convertToWav(file);
				formData.append('file', wavFile);
			} else {
				formData.append('file', file);
			}
			formData.append('audioLinkKey', s3FileName);
			formData.append('interviewKey', interviewKey);
			formData.append('questionId', currentQuestionIndex);
			formData.append('answeringTime', answeringTime);
			formData.append('startedDate', datetostring(interviewStartedTime));

			const time_spent = new Date() - interviewStartedTime;
			console.log(time_spent, 'time spent');
			formData.append('time_spent', time_spent);

			let response;
			if (sessionStorage.getItem('mock-interview')) {
				response = await axios.post(
					`${API_URL}/labs/mock-interview/saveMockInterviewCandidateAnswer`,
					formData
				);
			} else {
				response = await axios.post(`${API_URL}/labs/saveCandidateAnswer`, formData);
			}

			if (currentQuestionIndex === questionTimestamps.length - 2) {
				if (sessionStorage.getItem('mock-interview')) {
					response = await axios.get(
						`${API_URL}/labs/scores/evaluate-mock-interview/${interviewKey}`
					);
				} else {
					response = await axios.get(`${API_URL}/labs/scores/evaluate/${interviewKey}`);
				}
				console.log(response.data);
			}
		} catch (error) {
			console.error('Error updating answer:', error);
			setError(true);
		}
	};

	// Utility: Convert Audio File to 16kHz WAV
	const convertToWav = async (file) => {
		const audioContext = new (window.AudioContext || window.webkitAudioContext)({
			sampleRate: 16000,
		});

		const arrayBuffer = await file.arrayBuffer();
		const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);

		const offlineContext = new OfflineAudioContext(
			1, // Mono audio
			audioBuffer.duration * 16000, // Length of audio at 16kHz
			16000 // Sample rate
		);

		const source = offlineContext.createBufferSource();
		source.buffer = audioBuffer;
		source.connect(offlineContext.destination);
		source.start(0);

		const renderedBuffer = await offlineContext.startRendering();
		return audioBufferToWav(renderedBuffer);
	};

	// Utility: Encode Audio Buffer to WAV Blob
	const audioBufferToWav = (buffer) => {
		const key = `${uuidv4()}_${interviewKey}.wav`;
		const wavBuffer = encodeWAV(buffer.getChannelData(0), buffer.sampleRate);
		return new Blob([new DataView(wavBuffer)], { type: 'audio/wav', name: key });
	};

	// Helper: Encode PCM Samples to WAV Format
	const encodeWAV = (samples, sampleRate) => {
		const buffer = new ArrayBuffer(44 + samples.length * 2);
		const view = new DataView(buffer);

		const writeString = (view, offset, string) => {
			for (let i = 0; i < string.length; i++) {
				view.setUint8(offset + i, string.charCodeAt(i));
			}
		};

		writeString(view, 0, 'RIFF');
		view.setUint32(4, 36 + samples.length * 2, true);
		writeString(view, 8, 'WAVE');
		writeString(view, 12, 'fmt ');
		view.setUint32(16, 16, true);
		view.setUint16(20, 1, true); // PCM format
		view.setUint16(22, 1, true); // Mono channel
		view.setUint32(24, sampleRate, true);
		view.setUint32(28, sampleRate * 2, true); // Byte rate
		view.setUint16(32, 2, true); // Block align
		view.setUint16(34, 16, true); // Bits per sample
		writeString(view, 36, 'data');
		view.setUint32(40, samples.length * 2, true);

		let offset = 44;
		for (let i = 0; i < samples.length; i++, offset += 2) {
			const s = Math.max(-1, Math.min(1, samples[i]));
			view.setInt16(offset, s < 0 ? s * 0x8000 : s * 0x7fff, true);
		}

		return buffer;
	};

	const startInterview = () => {
		handleClose();
		setPlaying(true);
		setPlaysinline(true);
	};

	const replayQuestion = () => {
		reactPlayerRef.current?.seekTo(questionTimestamps[currentQuestionIndex].startTime);
		setPlaying(true);
		setIsListening(false);
		setIsRecording(false);
		setShowReplayButton(false);
	};

	const handleStopInterview = () => {
		setStopInterview(true);
	};

	const handleRestartInterview = () => {
		navigate('/login/candidate');
	};

	const handleIntroVideoEnded = () => {
		setHasIntroVideoEnded(true);
		setShowCountdownTimer(true);
	};

	const removeCountdownTimer = () => {
		setShowCountdownTimer(false);
	};
	if (proctoring_token && !isproctoringCheck) {
		return <></>;
	}
	console.log(videoUrl, playing, 'xyz video url');

	return (
		<>
			<Dialog open={alertDialogOpen && !proctoring_token}>
				<DialogTitle display="flex" alignItems={'center'}>
					<WarningAmberIcon color="red" />
					<b>Warning</b>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						We noticed that you moved out of the interview.{' '}
						<b>The hiring team is tracking</b> this activity. We recommend staying on
						this page until you complete the interview.
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="contained"
						style={{
							textTransform: 'none',
							color: '#fff',
							backgroundColor: 'green',
						}}
						onClick={restartPlaying}
					>
						Agree & Continue
					</Button>
				</DialogActions>
			</Dialog>

			<div id="component-div" style={{ height: '100vh', overflow: 'hidden' }}>
				<>
					<MobileView>
						<div>
							<Modal
								open={open}
								// onClose={handleClose}
								aria-labelledby="modal-modal-title"
								aria-describedby="modal-modal-description"
							>
								<Stack
									justifyContent="center"
									alignItems="center"
									sx={{
										position: 'absolute',
										top: '50%',
										left: '50%',
										transform: 'translate(-50%, -50%)',
										width: '400px',
										p: 4,
									}}
								>
									<Button
										variant="contained"
										onClick={startInterview}
										p={0}
										sx={{ width: '175px' }}
									>
										Start Interview
									</Button>
								</Stack>
							</Modal>
							{!hasInterviewEnded && <Header fullName={fullName} />}
							{loading && !hasInterviewEnded && <CircularStatic />}
							{!loading && (
								<>
									{!hasInterviewEnded &&
										hasIntroVideoEnded &&
										!showCountdownTimer && (
											<ReactPlayer
												ref={reactPlayerRef}
												url={mobileVideoUrl}
												// controls
												playing={playing}
												playsinline={playsinline}
												width="100%"
												height="100vh"
												progressInterval={1000}
												onProgress={handleProgress}
												onEnded={handleEnded}
												pip={false}
												onContextMenu={(e) => e.preventDefault()}
												config={{
													file: {
														attributes: {
															onContextMenu: (e) =>
																e.preventDefault(),
														},
													},
												}}
											/>
										)}
									<VideoAndScreenRecorder
										interviewKey={interviewKey}
										isStudentInterview={isStudentInterview}
										fullName={fullName}
										isDemo={isDemo}
										currentQuestionIndex={currentQuestionIndex}
										endUserInterview={endUserInterview}
									/>
								</>
							)}
							{!loading &&
								!!mobileIntroVideo &&
								!hasIntroVideoEnded &&
								!hasInterviewEnded && (
									<ReactPlayer
										ref={introVideoRef}
										playing
										playsinline
										onEnded={handleIntroVideoEnded}
										width="100%"
										height="90vh"
										url={mobileIntroVideo}
										onContextMenu={(e) => e.preventDefault()}
										config={{
											file: {
												attributes: {
													onContextMenu: (e) => e.preventDefault(),
												},
											},
										}}
									/>
								)}
							{showCountdownTimer && (
								<CountdownTimer
									removeCountdownTimer={removeCountdownTimer}
									timerText={'Interview starts in ...'}
								/>
							)}
							{questionsArray &&
								questionsArray.length > 0 &&
								currentQuestionIndex !== questionsArray.length - 1 &&
								!loading &&
								!hasInterviewEnded &&
								hasIntroVideoEnded &&
								!showCountdownTimer && (
									<Stack
										direction="row"
										width={'90vw'}
										display={'flex'}
										justifyContent={'center'}
										alignItems={'center'}
										position={'absolute'}
										top={'90px'}
										right={'20px'}
										left={'20px'}
									>
										<QuestionPopper
											questionTitle={`Question ${
												currentQuestionIndex + 1
											} of ${questionsArray.length - 1}`}
											questionText={questionsArray[currentQuestionIndex]}
											questionHint={
												questionHints.filter(
													(hint) =>
														hint.question_id === currentQuestionIndex
												)[0]
											}
										/>
									</Stack>
								)}
							<Stack
								direction="row"
								width={'100vw'}
								display={'flex'}
								justifyContent={'center'}
								alignItems={'center'}
								position={'absolute'}
								bottom={0}
								gap={'20px'}
							>
								{isRecording && !hasInterviewEnded && (
									<AudioRecorder
										interviewKey={interviewKey}
										handleAnswer={updateAnswerToDatabase}
										updateIsListening={updateIsListening}
										questionId={currentQuestionIndex}
										candidateName={fullName}
										isPractice={false}
										enabledRecording={isRecording}
										setAnsweringTime={setAnsweringTime}
									/>
								)}
								{showReplayButton && (
									<ReplayButton replayQuestion={replayQuestion} />
								)}
							</Stack>
						</div>
					</MobileView>
					<BrowserView>
						<div
							id="browserview-div"
							style={{
								height: '100vh',
								width: '100%',
								display: 'flex',
								flexDirection: 'column',
								overflow: 'hidden',
							}}
						>
							{!hasInterviewEnded && <Header fullName={fullName} />}
							{loading && !hasInterviewEnded && <CircularStatic />}
							{!loading && (
								<>
									{!hasInterviewEnded &&
										hasIntroVideoEnded &&
										!showCountdownTimer && (
											<div
												style={{
													flex: 1,
													position: 'relative',
													overflow: 'hidden',
												}}
											>
												<ReactPlayer
													ref={reactPlayerRef}
													url={videoUrl}
													// controls
													playing={desktopPlaying}
													playsinline={desktopPlaysinline}
													width="100%"
													height="100%"
													progressInterval={1000}
													onProgress={handleProgress}
													onEnded={handleEnded}
													pip={false}
													onContextMenu={(e) => e.preventDefault()}
													config={{
														file: {
															attributes: {
																onContextMenu: (e) =>
																	e.preventDefault(),
															},
														},
													}}
												/>
											</div>
										)}
									<VideoAndScreenRecorder
										interviewKey={interviewKey}
										isStudentInterview={isStudentInterview}
										fullName={fullName}
										isDemo={isDemo}
										currentQuestionIndex={currentQuestionIndex}
										endUserInterview={endUserInterview}
									/>
								</>
							)}
							{!loading &&
								!!desktopIntroVideo &&
								!hasIntroVideoEnded &&
								!hasInterviewEnded && (
									<div
										style={{
											flex: 1,
											position: 'relative',
											overflow: 'hidden',
										}}
									>
										<ReactPlayer
											ref={introVideoRef}
											playing
											playsinline
											onEnded={handleIntroVideoEnded}
											width="100%"
											height="100%"
											url={desktopIntroVideo}
											pip={false}
											onContextMenu={(e) => e.preventDefault()}
											config={{
												file: {
													attributes: {
														onContextMenu: (e) => e.preventDefault(),
													},
												},
											}}
										/>
									</div>
								)}
							{showCountdownTimer && (
								<CountdownTimer
									removeCountdownTimer={removeCountdownTimer}
									timerText={'Interview starts in ...'}
								/>
							)}
							{questionsArray &&
								questionsArray.length > 0 &&
								currentQuestionIndex !== questionsArray.length - 1 &&
								!loading &&
								!hasInterviewEnded &&
								hasIntroVideoEnded &&
								!showCountdownTimer && (
									<div
										style={{
											position: 'absolute',
											left: '40px',
											top: '80px',
											maxWidth: '400px',
										}}
									>
										<QuestionPopper
											questionTitle={`Question ${
												currentQuestionIndex + 1
											} of ${questionsArray.length - 1}`}
											questionText={questionsArray[currentQuestionIndex]}
											questionHint={
												questionHints.filter(
													(hint) =>
														hint.question_id === currentQuestionIndex
												)[0]
											}
										/>
									</div>
								)}
							<Stack
								direction="row"
								width={'100%'}
								display={'flex'}
								justifyContent={'center'}
								alignItems={'center'}
								position={'absolute'}
								bottom={'80px'}
								gap={'20px'}
							>
								{isRecording && !hasInterviewEnded && (
									<AudioRecorder
										interviewKey={interviewKey}
										handleAnswer={updateAnswerToDatabase}
										updateIsListening={updateIsListening}
										questionId={currentQuestionIndex}
										candidateName={fullName}
										isPractice={false}
										enabledRecording={isRecording}
										setAnsweringTime={setAnsweringTime}
									/>
								)}
								{showReplayButton && (
									<ReplayButton replayQuestion={replayQuestion} />
								)}
							</Stack>
						</div>
					</BrowserView>
					{/* <EndScreen interviewKey={interviewKey} fullName={fullName} hasVideoUploaded=} /> */}
				</>
			</div>
		</>
	);
};
