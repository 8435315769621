import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import {
	Box,
	Button,
	IconButton,
	Stack,
	Tooltip,
	Typography,
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import toast, { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { API_URL } from '../../global/constants';
import LoadingDialog from '../CodeEditor/LoadingDialog';
import { Header } from '../shared/Header';
import CandidateDetailDialog from './CandidateDetailDialog';

const CircularPtogresswithdots = () => {
	return (
		<Box
			position="relative"
			display="flex"
			justifyContent="center"
			alignItems="center"
			width={40}
			height={40}
			borderRadius="50%"
			border="5px solid rgba(0, 0, 0, 0.2)"
		>
			<span
				style={{
					fontSize: '25px',
					marginBottom: '17px',
					color: 'gray',
				}}
			>
				...
			</span>
		</Box>
	);
};

const CircularProgress = ({ progress }) => {
	//const percentage = (progress * 100) / 5;
	let percentage = 0;
	let decimal_value;
	if (!isNaN(parseFloat(progress))) {
		decimal_value = parseFloat(progress).toFixed(1);
		percentage = (progress * 100) / 5;
	} else {
		decimal_value = '0.0';
	}
	return (
		<div
			style={{
				width: '40px',
				height: '40px',
			}}
		>
			<CircularProgressbar
				strokeWidth={12}
				value={percentage}
				text={`${decimal_value}`}
				styles={buildStyles({
					textSize: '20px',
					textColor: percentage < 60 ? '#D32F2F' : '#2E7D32',
					pathColor: percentage < 60 ? '#D32F2F' : '#2E7D32',
				})}
			/>
		</div>
	);
};

// Define the rendering functions for columns
const renderShortlistCell = (params, changeStatus) => (
	<Checkbox
		key={`checkbox-${params.row.id}`}
		style={{ color: '#224C84' }}
		checked={params.value}
		disabled={params.value}
		onChange={() =>
			changeStatus(
				params.row.resume_id,
				params.row.first_name,
				params.row.last_name,
				params.row.email,
				params.row.contact,
				params.row.resume_link,
				params.row.source
			)
		}
	/>
);

const renderNameCell = (params) => (
	<Stack>
		<Tooltip title={'View Resume'}>
			<Button
				style={{
					textTransform: 'none',
					fontSize: '0.8rem',
					textAlign: 'start',
					justifyContent: 'flex-start',
					padding: '0px',
					width: 'fit-content',
				}}
			>
				<a
					target="_blank"
					rel="noreferrer"
					href={params.row.resume_link}
					style={{
						textDecoration: 'none',
						color: '#224C84',
					}}
				>
					{params.row.name}
				</a>
			</Button>
		</Tooltip>
		<Button
			style={{
				textTransform: 'none',
				fontSize: '0.7rem',
				textAlign: 'start',
				justifyContent: 'flex-start',
				padding: '0px',
				width: 'fit-content',
			}}
		>
			<a
				href={params.row.email}
				style={{
					textDecoration: 'none',
					color: '#224C84',
				}}
			>
				{params.row.email}
			</a>
		</Button>
		<Button
			style={{
				textTransform: 'none',
				fontSize: '0.7rem',
				textAlign: 'start',
				justifyContent: 'flex-start',
				padding: '0px',
				width: 'fit-content',
			}}
		>
			<a
				href={'tel:' + params.row.contact}
				style={{
					textDecoration: 'none',
					color: '#224C84',
				}}
			>
				{params.row.contact}
			</a>
		</Button>
	</Stack>
);

const renderNumberOfJobsCell = (params) => (
	<Typography>{params.value !== undefined ? params.value : 'No data found'}</Typography>
);

const renderActionCell = (
	params,
	setCandidateDetail,
	setCandidateDialogOpen,
	handleDeleteClick
) => (
	<Stack direction="row" spacing={1}>
		<Tooltip title="Edit Details">
			<IconButton
				onClick={() => {
					setCandidateDetail({
						first_name: params.row.first_name,
						last_name: params.row.last_name,
						email: params.row.email,
						contact: params.row.contact,
						resume_id: params.row.resume_id,
					});
					setCandidateDialogOpen(true);
				}}
				sx={{
					color: '#224C84',
				}}
			>
				<EditIcon />
			</IconButton>
		</Tooltip>
		<Tooltip title="Delete Resume">
			<IconButton
				onClick={() => handleDeleteClick(params.row)}
				sx={{
					color: '#d32f2f',
				}}
			>
				<DeleteIcon />
			</IconButton>
		</Tooltip>
	</Stack>
);

const ViewResumeEvaluation = () => {
	const { state } = useLocation();
	const { jobRole, jobId, companyId } = state;
	const [candidateDetail, setCandidateDetail] = useState({
		first_name: '',
		last_name: '',
		email: '',
		contact: '',
		resume_id: '',
	});
	const [candidateDialogOpen, setCandidateDialogOpen] = useState(false);
	const [resumeData, setResumeData] = useState([]);
	const [loading, setLoading] = useState(true);
	const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
	const [selectedResume, setSelectedResume] = useState(null);
	const formatHeader = (header) => {
		return header
			.split('_')
			.map((word) => word[0].toUpperCase() + word.slice(1))
			.join(' ');
	};

	const [columns, setColumns] = useState([]);

	const getData = async () => {
		setLoading(true);
		try {
			const { data } = await axios.get(`${API_URL}/labs/resumes/${companyId}/${jobId}`, {
				headers: {
					'Content-Type': 'multipart/form-data',
					authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
				},
			});
			console.log(data.data);
			if (data.success) {
				const rows = data.data.map((resume, index) => {
					const row = {
						resume_id: resume._id,
						id: index + 1,
						is_evaluated: resume.is_evaluated,
						name: `${resume.first_name} ${resume.last_name}`,
						email: resume.email,
						contact: resume.contact,
						shortlist: resume.is_shortlisted,
						first_name: resume.first_name,
						last_name: resume.last_name,
						resume_link: resume.resume_link,
						source: resume.source ?? '-',
						evaluation_messages: resume.evaluation_messages || [],
					};

					// Iterate through evaluations and extract values
					[
						'overall_score',
						'qualification',
						'certifications',
						'skills',
						'additionalskills',
						'experience',
						'Job Role Fit',
						'number_of_jobs',
					].forEach((type) => {
						const evaluation = resume.evaluations.find((e) => e.type === type);
						row[type] = evaluation ? evaluation.value : 'No data found';
					});
					return row;
				});

				setResumeData(rows);

				// Generate columns
				const new_columns = [
					{
						field: 'shortlist',
						headerName: 'Shortlist',
						align: 'center',
						headerAlign: 'center',
						renderCell: (params) => renderShortlistCell(params, changeStatus),
					},
					{
						field: 'id',
						headerName: 'ID',
						width: 20,
						align: 'center',
						headerAlign: 'center',
					},
					{ field: 'name', headerName: 'Name', width: 200, renderCell: renderNameCell },
					{
						field: 'source',
						headerName: 'Source',
						width: 160,
						align: 'center',
						headerAlign: 'center',
					},
					...[
						'overall_score',
						'qualification',
						'certifications',
						'skills',
						'additionalskills',
						'experience',
						'Job Role Fit',
					].map((type) => ({
						field: type,
						headerName: formatHeader(type),
						align: 'center',
						headerAlign: 'center',
						width: 105,
						renderCell: (params) => {
							if (params.value === 'No data found') {
								return (
									<Typography color="gray" style={{ fontSize: '0.8rem' }}>
										No data found
									</Typography>
								);
							}

							// For overall_score, return CircularProgress without tooltip
							if (params.field === 'overall_score') {
								return <CircularProgress progress={params.value} />;
							}

							const evaluationMessage =
								params.row.evaluation_messages?.find(
									(msg) =>
										msg.requirement.toLowerCase() ===
										params.field.toLowerCase().replace(/_/g, ' ')
								)?.evaluation || 'No evaluation message available';

							// Remove the final score from the main text if it exists
							let mainText = evaluationMessage
								.replace(/\*\*/g, '') // Remove all stars
								.replace(
									/Final Score Calculation:.*?Final Score:/gs,
									'Final Score:'
								) // Remove calculation part
								.replace(
									/Rounding this score leads us to\s*\n*Final Score:/g,
									'Final Score:'
								) // Remove rounding text
								.replace(/Final Score: \d+.*$/, '') // Remove final score section
								.trim();

							return (
								<Tooltip
									title={
										<Box
											sx={{
												p: 1,
												maxWidth: 400,
												maxHeight: 300,
												overflow: 'auto',
												'&::-webkit-scrollbar': {
													width: '8px',
												},
												'&::-webkit-scrollbar-track': {
													background: 'rgba(255,255,255,0.1)',
													borderRadius: '4px',
												},
												'&::-webkit-scrollbar-thumb': {
													background: 'rgba(255,255,255,0.3)',
													borderRadius: '4px',
													'&:hover': {
														background: 'rgba(255,255,255,0.4)',
													},
												},
												'& ul': {
													m: 0,
													pl: 2,
												},
											}}
										>
											<Typography
												variant="body2"
												sx={{
													whiteSpace: 'pre-wrap',
													mb: 1,
													color: '#fff',
													lineHeight: 1.5,
													fontSize: '0.8rem',
												}}
											>
												{mainText}
											</Typography>
										</Box>
									}
									arrow
									placement="top"
									sx={{
										'& .MuiTooltip-tooltip': {
											backgroundColor: 'rgba(33, 33, 33, 0.95)',
											borderRadius: 1,
											boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
											padding: 0,
										},
										'& .MuiTooltip-arrow': {
											color: 'rgba(33, 33, 33, 0.95)',
										},
									}}
								>
									<div>
										<CircularProgress progress={params.value} />
									</div>
								</Tooltip>
							);
						},
					})),
					{
						field: 'number_of_jobs',
						headerName: 'No Of Jobs',
						align: 'center',
						headerAlign: 'center',
						width: 100,
						renderCell: renderNumberOfJobsCell,
					},
					{
						field: 'action',
						headerName: 'Action',
						width: 120,
						renderCell: (params) =>
							renderActionCell(
								params,
								setCandidateDetail,
								setCandidateDialogOpen,
								handleDeleteClick
							),
					},
				];

				setColumns(new_columns);
			} else {
				toast.error(data.message);
			}
		} catch (error) {
			toast.error('An error occurred while fetching data.');
		} finally {
			setLoading(false);
		}
	};

	const changeStatus = async (
		resume_id,
		first_name,
		last_name,
		email,
		contact,
		resume_link,
		source
	) => {
		console.log(resume_id);
		const { data } = await axios.get(`${API_URL}/labs/resume/change-status/${resume_id}`, {
			headers: {
				'Content-Type': 'multipart/form-data',
				authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
			},
		});
		if (data.success) {
			toast.success(data.message);
			setResumeData((prev) => {
				let new_data = [...prev];
				for (let i = 0; i < new_data.length; i++) {
					if (new_data[i].resume_id === resume_id) {
						new_data[i].shortlist = !new_data[i].shortlist;
					}
				}
				return new_data;
			});
			setLoading(true);
			const type = 'shortlisted_resume';
			const formData = new FormData();
			formData.append('first_name', first_name);
			formData.append('last_name', last_name);
			formData.append('contact', contact);
			formData.append('email', email);
			formData.append('resume_link', resume_link);
			formData.append('type', type);
			formData.append('source', source);
			const { data: interviewData } = await axios.post(
				`${API_URL}/labs/applicant/new/${companyId}/${jobId}`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${
							JSON?.parse(sessionStorage?.getItem('admin'))?.token
						}`,
						'content-type': 'multipart/form-data',
					},
				}
			);
			if (interviewData.success) {
				toast.success('Interview Created Successfully');
			} else {
				toast.error('Error Creating Interview');
			}
			setLoading(false);
		} else {
			toast.error(data.message);
		}
	};

	const handleDeleteClick = (resume) => {
		setSelectedResume(resume);
		setDeleteConfirmOpen(true);
	};

	const handleDeleteConfirm = async () => {
		try {
			const { data } = await axios.delete(
				`${API_URL}/labs/delete-resume-evaluation/${selectedResume.resume_id}`,
				{
					headers: {
						authorization: `Bearer ${JSON?.parse(sessionStorage?.getItem('admin'))?.token}`,
					},
				}
			);
			if (data.success) {
				toast.success('Resume deleted successfully');
				setResumeData((prev) =>
					prev.filter((item) => item.resume_id !== selectedResume.resume_id)
				);
			} else {
				toast.error(data.message || 'Failed to delete resume');
			}
		} catch (error) {
			toast.error('An error occurred while deleting the resume');
		} finally {
			setDeleteConfirmOpen(false);
			setSelectedResume(null);
		}
	};

	const handleDeleteCancel = () => {
		setDeleteConfirmOpen(false);
		setSelectedResume(null);
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<Stack justifyContent={'center'} alignItems={'center'} pl={5} pr={5}>
			<Header />
			<Toaster />
			<LoadingDialog open={loading} />
			<CandidateDetailDialog
				details={candidateDetail}
				setDetails={setCandidateDetail}
				open={candidateDialogOpen}
				setOpen={setCandidateDialogOpen}
				setResumeData={setResumeData}
			/>
			<Dialog
				open={deleteConfirmOpen}
				onClose={handleDeleteCancel}
				aria-labelledby="delete-dialog-title"
			>
				<DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
				<DialogContent>
					<Typography>
						Do you really want to delete {selectedResume?.name}'s resume evaluation?
					</Typography>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDeleteCancel} color="primary">
						Cancel
					</Button>
					<Button onClick={handleDeleteConfirm} color="error">
						Delete
					</Button>
				</DialogActions>
			</Dialog>
			<Typography m={3} fontSize={'1.5rem'} textAlign={'center'} fontWeight={'bold'}>
				Resume Evaluations
				<br /> for {jobRole}
			</Typography>
			{/* {resumeData.length===0 && !loading && <Typography> No Evaluations Available</Typography>} */}
			{resumeData.length > 0 && (
				<Box sx={{ height: '70vh', width: '100%', bgcolor: '#fff' }}>
					<DataGrid
						rows={resumeData}
						rowHeight={80}
						columns={columns}
						slots={{
							noRowsOverlay: () => <Typography>No Evaluations Available</Typography>,
							toolbar: GridToolbar,
						}}
						slotProps={{
							toolbar: {
								csvOptions: {
									allColumns: true,
								},
							},
						}}
						initialState={{
							pagination: {
								paginationModel: {
									pageSize: 10,
								},
							},
							columns: {
								columnVisibilityModel: {
									email: false,
									contact: false,
								},
							},
						}}
						pageSizeOptions={[10, 20, 30, 50, 100]}
						// checkboxSelection
						disableRowSelectionOnClick
					/>
				</Box>
			)}
		</Stack>
	);
};

export default ViewResumeEvaluation;
