import React, { useState, useEffect } from 'react';
import {
	TextField,
	Select,
	MenuItem,
	InputLabel,
	FormControl,
	Box,
	Typography,
	Button,
} from '@mui/material';
import axios from 'axios';

import CustomizedHook from './customisedhook';

import { useParams } from 'react-router';
import { API_URL } from '../../../global/constants';
import { Country, State, City } from 'country-state-city';
import { useLocation } from 'react-router-dom';
const FadeandLoading = () => {
	return (
		<>
			<Box
				sx={{
					position: 'fixed', // Use fixed positioning to cover the entire viewport
					top: 0,
					bottom: 0,
					right: 0,
					left: 0,
					width: '100%',
					height: '100%',
					backgroundColor: 'white',
					opacity: '0.67',
					zIndex: 10,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				{/* This box covers the entire page */}
			</Box>
			<Box
				sx={{
					position: 'fixed', // Use fixed positioning to center the progress bar
					top: '50%',
					left: '50%',
					transform: 'translate(-50%, -50%)', // Center the box using transform
					width: '100px', // Set a fixed size for the progress bar container
					height: '100px',

					opacity: '0.67',
					zIndex: 20,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
				}}
			>
				<div className="loader"></div>
			</Box>
		</>
	);
};

const JobForm = ({ state, handleGenerateClick, dispatch }) => {
	const location = useLocation();
	const pathName = location.pathname;
	console.log(location.pathname, 'location');
	const handleChange = (field) => (event, newValue) => {
		if (
			field === 'qualifications' ||
			field === 'certifications' ||
			field === 'mustHaveSkills' ||
			field === 'additionalSkills' ||
			field === 'department'
		) {
			dispatch({ type: 'UPDATE_ARRAY_FIELD', field, value: newValue });
		} else if (field === 'includectc') {
			dispatch({ type: 'UPDATE_BOOL', field, value: state[field] });
		} else if (field === 'workType') {
			dispatch({ type: 'UPDATE_FIELD', field, value: event.target.value });
		} else if (field === 'minExperience' || field === 'maxExperience') {
			dispatch({
				type: 'UPDATE_EXPERIENCE',
				field: field === 'minExperience' ? 'min' : 'max',
				value: event.target.value,
			});
		} else {
			dispatch({ type: 'UPDATE_FIELD', field, value: event.target.value });
		}
	};

	const handleSubmit = (event) => {
		event.preventDefault();
	};

	const [Generatebuttonstate, setGeneratebuttonstate] = useState('idle');
	const handleclick = async () => {
		setGeneratebuttonstate('loading');
		await handleGenerateClick();
		setGeneratebuttonstate('idle');
	};

	const [states, setStates] = useState([]);
	const [cities, setCities] = useState([]);
	const { id } = useParams();
	console.log(id, 'params id');
	useEffect(() => {
		async function fetchdetails() {
			const data = await axios.get(`${API_URL}/jobdescription/getdetails/${id}`);
			const jobdescription = data.data.jobdescription;
			const { job_id: job_details, experience, state, country, city } = jobdescription;
			console.log(job_details, 'job_details');
			const {
				job_role,
				roleType,
				qualifications,
				additional_requirements,
				skills_required,
				expected_ctc,
				department,
				certifications,
				type: worktype,
			} = job_details;

			handleChange('certifications')(null, certifications);
			handleChange('qualifications')(null, qualifications);
			handleChange('mustHaveSkills')(null, skills_required);
			handleChange('department')(null, department);
			handleChange('additionalSkills')(null, additional_requirements);

			dispatch({ type: 'UPDATE_FIELD', field: 'position', value: job_role });
			dispatch({ type: 'UPDATE_FIELD', field: 'roleType', value: roleType });
			dispatch({ type: 'UPDATE_FIELD', field: 'workType', value: worktype });

			dispatch({ type: 'UPDATE_FIELD', field: 'compensation', value: expected_ctc });
			dispatch({ type: 'UPDATE_FIELD', field: 'country', value: country });
			setTimeout(
				() => dispatch({ type: 'UPDATE_FIELD', field: 'stateName', value: state }),
				[2000]
			);
			console.log('city', city);
			setTimeout(
				() => dispatch({ type: 'UPDATE_FIELD', field: 'city', value: city }),
				[2000]
			);

			dispatch({ type: 'UPDATE_EXPERIENCE', field: 'min', value: experience.min });
			dispatch({ type: 'UPDATE_EXPERIENCE', field: 'max', value: experience.max });

			console.log(data, 'job data');
		}
		if (id) {
			fetchdetails();
		}
	}, [id]);
	console.log(state.city, 'state.city');
	useEffect(() => {
		if (state.country) {
			const fetchedStates = State.getStatesOfCountry(state.country);
			setStates(fetchedStates);
			setCities([]);
			dispatch({ type: 'UPDATE_FIELD', field: 'stateName', value: '' });
			dispatch({ type: 'UPDATE_FIELD', field: 'city', value: '' });
		}
	}, [state.country, dispatch]);

	useEffect(() => {
		if (state.stateName) {
			const fetchedCities = City.getCitiesOfState(state.country, state.stateName);
			setCities(fetchedCities);
			if (location.pathname.includes('edit')) {
				return;
			}
			dispatch({ type: 'UPDATE_FIELD', field: 'city', value: '' });
		}
	}, [state.country, state.stateName, dispatch]);

	const countries = Country.getAllCountries();

	return (
		<Box
			component="form"
			onSubmit={handleSubmit}
			sx={{ display: 'flex', flexDirection: 'column', gap: 2, maxWidth: 600, margin: 'auto' }}
		>
			{Generatebuttonstate === 'loading' && <FadeandLoading />}
			<Typography variant="h6" component="h1" gutterBottom style={{ fontWeight: 'bold' }}>
				Describe the job you're looking to hire for
			</Typography>

			<TextField
				label="Position"
				value={state.position}
				onChange={handleChange('position')}
				fullWidth
				required
			/>
			<TextField
				label="Company"
				value={state.company}
				InputProps={{
					readOnly: true,
				}}
				fullWidth
				required
			/>
			<CustomizedHook
				field="qualifications"
				handleChange={handleChange}
				state={state}
				isrequired={true}
			/>
			<CustomizedHook field="certifications" handleChange={handleChange} state={state} />
			<FormControl fullWidth>
				<InputLabel id="work-type-label">Work Type *</InputLabel>
				<Select
					labelId="work-type-label"
					value={state.workType}
					onChange={handleChange('workType')}
					label="Work Type *"
				>
					{['Full-time', 'Remote', 'Hybrid'].map((option) => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))}
				</Select>
			</FormControl>

			<Box sx={{ display: 'flex', gap: 2 }}>
				<FormControl fullWidth required>
					<InputLabel id="min-experience-label">Min Experience</InputLabel>
					<Select
						labelId="min-experience-label"
						value={state.experience.min}
						onChange={(e) => handleChange('minExperience')(e)}
						label="Min Experience"
					>
						{Array.from({ length: 31 }, (_, i) => i).map((option) => (
							<MenuItem key={option} value={option}>
								{option} years
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<FormControl fullWidth required>
					<InputLabel id="max-experience-label">Max Experience</InputLabel>
					<Select
						labelId="max-experience-label"
						value={state.experience.max}
						onChange={(e) => handleChange('maxExperience')(e)}
						label="Max Experience"
					>
						{Array.from({ length: 31 }, (_, i) => i).map((option) => (
							<MenuItem key={option} value={option}>
								{option} years
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</Box>
			<FormControl fullWidth>
				<InputLabel id="role-type-label">Role Type *</InputLabel>
				<Select
					labelId="role-type-label"
					value={state.roleType}
					onChange={handleChange('roleType')}
					label="Role Type"
				>
					{['Individual contributor', 'Team management'].map((option) => (
						<MenuItem key={option} value={option}>
							{option}
						</MenuItem>
					))}
				</Select>
			</FormControl>
			<div>
				<FormControl fullWidth margin="normal">
					<InputLabel id="country-label">Country *</InputLabel>
					<Select
						labelId="country-label"
						value={state.country}
						onChange={handleChange('country')}
						label="Country"
					>
						{countries.map((country) => (
							<MenuItem key={country.isoCode} value={country.isoCode}>
								{country.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl fullWidth margin="normal" disabled={!state.country}>
					<InputLabel id="state-label">State *</InputLabel>
					<Select
						labelId="state-label"
						value={state.stateName}
						onChange={handleChange('stateName')}
						label="State"
						required
					>
						{states.map((state) => (
							<MenuItem key={state.isoCode} value={state.isoCode}>
								{state.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl fullWidth margin="normal" disabled={!state.stateName}>
					<InputLabel id="city-label">City *</InputLabel>
					<Select
						labelId="city-label"
						value={state.city}
						onChange={handleChange('city')}
						label="City"
						required
					>
						{cities.map((city) => (
							<MenuItem key={city.name} value={city.name}>
								{city.name}
							</MenuItem>
						))}
					</Select>
				</FormControl>
			</div>

			<CustomizedHook field="department" handleChange={handleChange} state={state} />
			<CustomizedHook field="mustHaveSkills" handleChange={handleChange} state={state} />
			<CustomizedHook field="additionalSkills" handleChange={handleChange} state={state} />

			<TextField
				label="Compensation (LPA)"
				variant="outlined"
				value={state.compensation}
				onChange={handleChange('compensation')}
				type="number"
				InputProps={{ inputProps: { min: 0 } }}
			/>

			<Button
				onClick={handleclick}
				type="submit"
				variant="contained"
				color="primary"
				disabled={Generatebuttonstate === 'loading' || pathName.includes('edit')}
			>
				Generate
			</Button>
		</Box>
	);
};

export default JobForm;
